<template>
  <div class="application">
    <div class="subscribe-row">
      已订购系统数量：<span v-if="list" class="num">{{ list.length || 0 }}</span>
      <el-dropdown class="add-btn" @command="handleCommand">
        <span class="el-dropdown-link">
          更多操作
          <i class="el-icon-arrow-down el-icon--right" />
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="add">
            新增
          </el-dropdown-item>
          <el-dropdown-item command="app">
            应用市场
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-table
      :data="list"
      style="width: 100%"
      stripe
    >
      <el-table-column
        prop="name"
        label="应用名称"
        width="180"
        height="44"
      />
      <el-table-column
        prop="appKey"
        label="appKey"
        height="44"
      />
      <el-table-column
        prop="callbackUrl"
        label="回调地址"
        height="44"
      />
      <el-table-column
        prop="redirectUrl"
        label="回跳地址"
        height="44"
      />
      <el-table-column
        fixed="right"
        label="操作"
      >
        <template slot-scope="scope">
          <el-link type="primary" :underline="false" @click="openAddDialog(scope.row)">
            编辑
          </el-link>
          <el-link :type="scope.row.status ? 'danger' : 'primary'" :underline="false" @click="switchApplication(scope.row.id, scope.row.status)">
            {{ scope.row.status ? '停用' : '启用' }}
          </el-link>
          <el-link type="primary" :underline="false" @click="viewDetail(scope.row.appSecret)">
            查看秘钥
          </el-link>
          <el-link type="danger" :underline="false" @click.stop="resetApplication(scope.row.appKey)">
            重置
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="form.id ? '编辑应用' : '新增应用'"
      :visible.sync="dialogVisible"
      width="50%"
    >
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="100px" class="demo-ruleForm">
        <el-form-item label="应用名称" prop="name">
          <el-input v-model="form.name" size="small" />
        </el-form-item>
        <el-form-item label="回调地址" prop="callbackUrl">
          <el-input v-model="form.callbackUrl" size="small" />
        </el-form-item>
        <el-form-item label="回跳地址" prop="redirectUrl">
          <el-input v-model="form.redirectUrl" size="small" />
        </el-form-item>
        <el-form-item label="应用类型" prop="sns">
          <el-select v-model="form.sns" placeholder="请选择" size="small">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="可见范围" prop="visibleRange">
          <div>
            <el-radio v-model="form.rangeType" :label="0">
              全部员工
            </el-radio>
          </div>
          <div>
            <el-radio v-model="form.rangeType" :label="1">
              部分员工
            </el-radio>
            <el-button v-if="form.rangeType === 1" type="text" @click="openRangeDialog">
              编辑权限
            </el-button>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleClose('ruleForm')">取 消</el-button>
        <el-button size="small" type="primary" @click="handleSubmit('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="查看秘钥"
      :visible.sync="secretDialogVisible"
      width="50%"
    >
      <div>秘钥：{{ appSecret }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="secretDialogVisible=false">取 消</el-button>
        <el-button size="small" type="primary" @click="copySecret">复 制</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="选择部门与人员"
      :visible.sync="viewRangeDialog"
      append-to-body
      width="50%"
      custom-class="viewRangeDialog"
    >
      <div class="content">
        <div class="tree">
          <el-input
            v-model="filterText" 
            placeholder="搜索"
            prefix-icon="el-icon-search"
            size="small"
          />
          <el-tree
            ref="tree"
            :data="treeData"
            :props="{label: 'name'}"
            :default-checked-keys="defaultCheckedIds"
            :default-expand-all="false"
            :node-key="'id'"
            show-checkbox
            style="margin-top: 6px"
            :filter-node-method="filterNode"
          >
            <template #default="{data}">
              <span :id="data.id">{{ data.name }}</span>
            </template>
          </el-tree>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="canclePermission">取 消</el-button>
        <el-button size="small" type="primary" @click="addPermission">确 认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getAppManageList, resetApplicationSecret, createApplication,
  updateApplication, switchApplication, getAppPermission, setAppPermission
} from '@/api/application';
export default {
  data () {
    return {
      list: [],
      dialogVisible: false,
      form: {
        id: null,
        name: '',
        callbackUrl: '',
        redirectUrl: '',
        visibleRange: '',
        rangeType: 0,
        bizList: [],
        sns: '',
      },
      rules: {
        name: [
          { required: true, message: '应用名称不能为空', trigger: 'blur' }
        ],
        callbackUrl: [
          { required: true, message: '回调地址不能为空', trigger: 'blur' }
        ],
        redirectUrl: [
          { required: true, message: '回跳地址址不能为空', trigger: 'blur' }
        ],
        sns: [
          { required: true, message: '企业类型不能为空', trigger: 'blur' }
        ],
      },
      appSecret: null,
      secretDialogVisible: false,
      viewRangeDialog: false,
      treeData: [],
      defaultCheckedIds: [],
      filterText: '',
      options: [
        { label: '自建应用', value: 'ACCOUNT' },
        { label: '企微应用', value: 'WECHAT' }
      ],
    };
  },
  watch: {
    filterText (val) {
      this.$refs.tree.filter(val);
    }
  },
  mounted () {
    this.fetch();
  },
  methods: {
    fetch () {
      getAppManageList().then(res => {
        this.list = res;
      });
    },
    filterNode (value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    viewDetail (appSecret) {
      this.appSecret = appSecret;
      this.secretDialogVisible = true;
    },
    copySecret () {
      let oInput = document.createElement('input');   // 直接构建input
      oInput.value = this.appSecret;  // 设置内容
      document.body.appendChild(oInput);    // 添加临时实例
      oInput.select();   // 选择实例内容
      document.execCommand('Copy');   // 执行复制
      document.body.removeChild(oInput);
      this.$message.success('复制成功');
    },
    resetApplication (appKey) {
      this.$confirm('确定重置秘钥?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        resetApplicationSecret({ appKey }).then(() => {
          this.$message.success('重置成功');
          this.fetch();
        });
      }).catch(() => { });
    },
    openAddDialog (item) {
      if (item) {
        this.form.id = item.id;
        this.form.name = item.name;
        this.form.callbackUrl = item.callbackUrl;
        this.form.redirectUrl = item.redirectUrl;
        this.form.rangeType = item.rangeType;
        this.form.sns = item.sns;
      } else {
        this.form.id = null;
        this.form.name = '';
        this.form.callbackUrl = '';
        this.form.redirectUrl = '';
        this.form.rangeType = 0;
        this.form.sns = '';
      }
      this.dialogVisible = true;
    },
    handleClose (formName) {
      this.dialogVisible = false;
      this.$refs[formName].resetFields();
    },
    handleSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const rangeType = this.form.rangeType;
          const bizList = this.form.bizList;
          // 部分员工
          if (rangeType && bizList.length === 0) {
            this.$message.error('请选择权限');
            return;
          }
          if (this.form.id) {
            updateApplication(this.form);
            setAppPermission(this.form).then(() => {
              this.$message.success('更新成功');
              this.handleClose(formName);
              this.fetch();
            });
          } else {
            createApplication(this.form).then(() => {
              this.$message.success('创建成功');
              this.handleClose(formName);
              this.fetch();
            });
          }
        } else {
          return false;
        }
      });
    },
    // 启用停用应用
    switchApplication (id, status) {
      this.$message.closeAll();
      if (status) {
        this.$confirm('确定停用该应用吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          switchApplication({ id }).then((res) => {
            this.$message.success('已停用');
            this.fetch();
          });
        });
      } else {
        switchApplication({ id }).then((res) => {
          this.$message.success('已启用');
          this.fetch();
        });
      }
    },
    // 可见范围
    openRangeDialog () {
      const appId = this.form.id;
      getAppPermission({ appId }).then(res => {
        this.treeData = res.treeList;
        if (res.selectedList && res.selectedList.length !== 0) {
          const ids = res.selectedList.map(item => item.id);
          this.$nextTick(() => {
            this.$refs.tree.setCheckedKeys(ids);
          });
        } else {
          this.$nextTick(() => {
            this.$refs.tree.setCheckedKeys([]);
          });
        }
        this.viewRangeDialog = true;
      });
    },
    canclePermission () {
      this.form.bizList = [];
      this.viewRangeDialog = false;
    },
    addPermission () {
      this.form.bizList = [];
      const ids = this.$refs.tree.getCheckedKeys();
      this.setbizList(this.treeData, ids);
      this.viewRangeDialog = false;
    },
    setbizList (data, ids) {
      data.forEach(item => {
        if (item.children && item.children.length !== 0) {
          this.setbizList(item.children, ids);
        } else {
          if (ids.includes(item.id)) {
            this.form.bizList.push({
              bizId: item.id,
              name: item.name,
              type: item.type,
            });
          }
        }
      });
    },
    handleCommand (command) {
      if (command === 'app') {
        this.$router.push({ path: '/appmanage/add' });
      } else {
        this.openAddDialog();
      }
    },
  },
};
</script>
<style lang="scss">
.application {
  .el-table__body {
    .cell {
      line-height: 44px !important;
    }
  }
}
</style>
<style lang="scss" scoped>
@import '@/style/imports/variables.scss';

.application {
  .subscribe-row {
    padding: 28px 32px;
    background: #fff;
    color: #666;
    margin-bottom: 25px;
    .num {
      font-size: 20px;
      color: $color-primary;
      font-weight: 600;
    }
    .add-btn {
      float: right;
      cursor: pointer;
    }
  }
  .el-link--primary,
  .el-link--danger {
    margin-right: 10px;
  }
}
.viewRangeDialog {
  .content {
    min-height: 400px;
    max-height: 50vh;
    overflow: auto;
  }
}
</style>
